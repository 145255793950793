import { tns } from "tiny-slider/src/tiny-slider";

let tiny_carousel = document.getElementsByClassName("tiny-carousel");

if(tiny_carousel.length){
  let tiny = tns({
    container: '.tiny-carousel',
    items: 4,
    mouseDrag: true,
    swipeAngle: false,
    speed: 400,
    gutter: 10,
    loop: false,
    autoplay: true,
    rewind: true,
    responsive:{
      0:{
        items:1,
        controls: false,
        nav: true,
        navPosition: 'bottom'
      },
      600:{
        items:2,
      },
      1000:{
        items:3,
      }
    }
  });
}